import Content from "./components/Content"
import './App.css';

function App() {
  return (
    <>
    <Content></Content>
    </>
  );
}

export default App;
